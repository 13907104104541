<template lang="pug">
	section#esqueceuSenha
		.wrapper
			img(:src="require('@images/others/404.png')")
			p Opa, parece que a tela que está procurando não existe.
			router-link(:to="{'name': 'home'}").verde retornar para o site
</template>

<script>
export default {
  components: {}
};
</script>

<style lang="stylus" scoped src="./Error404.styl"></style>
